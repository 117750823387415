.back {
  background-color: #1b7189;
  // width: 50%;
  // height: 100%;
}

.logocontain {
  margin-top: 15em;
  margin-left: 23em;
}

.logotext {
  color: white;

  text-align: center;
  margin-left: -6em;
}

.signin {
  background-color: #e7e7e7;
  // width: 50%;
  // height: 100%h;
}

#page-content {
  width: 100%;
  position: relative;
  // margin-left: 240px;
}

.loginCard {
  background: #1a7088;
}

.logoCard {
  min-height: 100% !important;
  min-width: 100% !important;
  margin: 0px;
  padding: 0px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.logoSection {
  display: grid;
  background: #1a7088 !important;
  height: 100% !important;
  // width: 100% !important;
  margin: 0px !important;
  padding: 0px !important;
  align-items: center;
  align-content: center;
  text-align: center;
  justify-self: center;
}

.logo {
  max-width: 7em;
  max-height: 7em;
}

.logoText {
  color: #fff;
  font-size: large;
  text-align: center;
}

.loginButton {
  display: block;
  background: #1a7088 !important;
  color: #fff !important;
  height: 40px;
  font-size: 18px;
  font-family: "Poppins";
  font-style: normal;
  border: none;
  border-radius: 5px;
  margin: auto;
  font-weight: 600;
  width: 80%;
}

.forgotPasswordButton {
  display: block;
  background: #1a7088 !important;
  color: #fff !important;
  // float: 'right' !important;
  margin-top: 0.5em;
}

.formRow {
  // display: block !important;
  margin-top: 1em;
}

.buttonColumn {
  // display: block !important;

  margin-top: 1em;
}

.loginPage {
  padding: 70px;
}

.MuiFormControl-marginNormal {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

@media (max-width: 800px) {
  .loginPage {
    padding: 0px !important;
  }
}

.goButton {
  background-color: #1b7189;
  width: 400px;
  height: 40px;
  color: #ffffff;
  font-size: 18px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  align-items: center;
  border: none;
  border-radius: 5px;
}

#logoStyle {
  max-width: 7em;
  max-height: 7em;
}

// .logoHeding {
//     color: #fff;
// }

.signinHeding {
  width: 106px;
  height: 48px;
  margin-left: -10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 150%;
  /* identical to box height, or 48px */
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000;
}

.logoHeding {
  margin-top: 30px;
  // margin-left: -20px;
  /* text-transform: uppercase; */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 55px;
  line-height: 150%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
}

.logoImage {
  width: 75%;
  height: 220px;
  margin-top: 45px;
  margin-left: 1px;
}

@media (max-width: 950px) {
  .logoBackground {
    border-radius: 50%;
    background-color: #fff;
    width: 180px;
    height: 180px;
    /* margin-top: -150px; */
    margin-left: 60px;
    align-items: center;
  }

  .logoImage {
    width: 60%;
    height: 120px;
    margin-top: 30px;
    margin-left: 1px;
  }

  .logoHeding {
    margin-top: 25px;
    margin-left: 8px;
    font-size: 45px;
  }
}

@media (max-width: 800px) {
  .goButton {
    width: 100%;
  }
}
