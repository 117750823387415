.signupLink {
    text-align: left;
}

.authentication-inner {
    align-items: initial;
}

.tenantList {
    text-align: left;
    list-style: none;
}

.apLeftBackground {
    background-color: #4080BF;
}

#signupFormLabel {
    float: left;
    margin-bottom: 0em !important;
}

.siginButton {
    background-color: #4080BF;
    width: 400px;
    height: 40px;
    color: #FFFFFF;
    font-size: 18px;
    font-family: 'Poppins';
    font-style: normal;
    align-items: center;
    border: none;
    border-radius: 5px;
}

.signinHeding {
    width: 106px;
    height: 48px;
    margin-left: -10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 150%;
    /* identical to box height, or 48px */
    text-align: center;
    letter-spacing: -0.02em;
    color: #000000;
}

.logoHeding {
    margin-top: 30px;
    /* margin-left: -22px; */
    /* text-transform: uppercase; */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 55px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #FFFFFF;
}

.apLogoImage {
    width: 75%;
    height: 220px;
    margin-top: 45px;
    margin-left: 14%;
}

@media (max-width: 950px) {
    .logoBackground {
        border-radius: 50%;
        background-color: #fff;
        width: 180px;
        height: 180px;
        /* margin-top: -150px; */
        margin-left: 60px;
        align-items: center;
    }

    .apLogoImage {
        width: 100%;
        height: 120px;
        margin-top: 30px;
        margin-left: 1px;
    }

    .logoHeding {
        margin-top: 25px;
        margin-left: 8px;
        font-size: 45px;
    }
}

.adminSigninHeding {
    /* width: 106px; */
    height: 48px;
    margin-left: -10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 150%;
    /* identical to box height, or 48px */
    text-align: center;
    letter-spacing: -0.02em;
    color: #000000;
}