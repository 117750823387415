.contact-us {
    padding: 160px 0px;
    background-image: url(./assets/images/about1.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

#contactForm {
    background: #fff;
    border-radius: 5px;
    padding: 3em;
}

#overview p {
    font-weight: normal;
}

#ourFeatures li {
    list-style-type: disc !important;
}

#overview li i {
    font-size: 20px;
    margin-right: 5px;
}

#overview li {
    font-weight: normal;
    line-height: 2.1rem;
    font-size: 17px;

}

.iconColumn {
    padding: 0em;
    text-align: right;
}

.aboutUs {
    padding-left: 8em;
    font-size: 17px;
}

.ourServices {
    padding-right: 9em;
}

.overView {
    padding: 0px 0px 0px 10em;
}

.taxonomyHubHeding {
    display: inline-flex;
    margin: 1.2em 8.5em 0.8em 8.5em;
}

@media (max-width: 767px) {
    .copyright {
        text-align: center;
    }

    .aboutUs {
        padding-left: 0em;
        font-size: 17px;
    }

    .ourServices {
        padding-right: 0em;
    }

    .overView {
        padding: 0px;
    }

    .taxonomyHubHeding {
        display: inline-flex;
        margin: 0.5em 6em;
    }
}