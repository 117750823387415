.codeBtn{
        cursor: pointer;
            background: rgb(27, 113, 137);
            color: rgb(255, 255, 255);
            height: 35px !important;
            /* background: green !important; */
            color: #fff !important;
            width: 40px !important;
            padding: 4px !important;
            margin-left: 0em !important;
            margin-right: -0.7em !important;
}