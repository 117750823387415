.cardHeading {
  font-size: 18px;
  margin-bottom: 30px;
  color: #2a2a2a;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.mainCard {
  background: #fff !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
}
