/* PAGE STYLES
   ----------------------------- */

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#page-content {
  width: 100%;
  position: relative;
  margin-left: $sidebarWidth;
  // margin-top: $headerHeight;
  background-color: #d5d8dc;

  &.has-alert {
    // margin-top: 48px;
    margin-top: 2.5em;
  }
}

#primary-content {
  // padding: $pageContentPadding;
  padding: 4em 3em 2em 3em;
  outline: none;
  // background: #e5e5e5;
  background: #d5d8dc;
  height: 100%;
  width: 100%;
  display: inline-table;
}

.page-container {
  width: 100%;
  margin: auto;
  max-width: 1170px;
}

.container {
  width: 100%;
  max-width: 1000px;
  padding-left: 0;
  padding-right: 0;
}

.app-body {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-grow: 1;
  overflow-x: hidden;
  background: #f5f5f5;
}

.content-wrap {
  background-color: #ffffff;
  padding: 32px;
  border-radius: 3px;
  @include cardShadow;
}

.full-bleed-content {
  margin: -$pageContentPadding;
}

.narrow-page-container {
  max-width: 1000px;
  margin: auto;
}

// Responsive

//collapsed side menu
@media screen and (min-width: $breakPointSm) {
  .side-menu-collapsed {
    #page-content {
      margin-left: 50px;
      // margin-top: $headerHeight;
    }
  }
}

@media screen and (max-width: $breakPointSm) {
  #page-content {
    margin-left: 0px;
    // margin-top: 50px;
    // margin-top: $headerHeight;
  }
}

@media screen and (max-width: $breakPointXs) {
  #page-content {
    // margin-top: 50px;
    // margin-top: $headerHeight;
  }
}
