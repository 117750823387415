html {
  scrollbar-width: thin;
}

html,
body {
  font-size: 14px !important;
  color: #333333;
  width: 100%;
  padding: 0;
  margin-left: 0;
  margin-right: 0;
  font-weight: 400 !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  overflow-x: auto;
}

.ptsActiveHeader {
  display: flex;
  /* position: fixed; */
  background: transparent;
  color: white;
  /* width: 100%; */
}

.ptsInActiveHeader {
  /* position: fixed; */
  /* display: flex; */
  background: #054e63;
  /* color: black; */
  /* width: 100%; */
}

@-webkit-viewport {
  width: device-width;
}

@-moz-viewport {
  width: device-width;
}

@-ms-viewport {
  width: device-width;
}

@-o-viewport {
  width: device-width;
}

@viewport {
  width: device-width;
}

.page-content,
section {
  overflow: hidden;
}

.roomy-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.roomy-80 {
  padding-top: 100px;
  padding-bottom: 80px;
}

.roomy-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.roomy-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.roomy-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.roomy-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.text-muted {}

.text-primary {
  color: #3f92d1 !important;
}

.text-finence {
  color: #13a0b2 !important;
}

.text-mega {
  color: #3f92d1 !important;
}

.text-white {
  color: #fff !important;
}

.text-black {
  color: #000 !important;
}

.text-warning {}

.text-danger {}

.disabled {
  cursor: not-allowed;
}

.lightbg {
  background-color: #f2f2f2;
}

.bg-primary {
  background-color: #3f92d1;
}

.bg-blue {
  background-color: #3f92d1;
}

.bg-mega {
  background-color: #1b1b1b;
}

.bg-grey {
  background-color: #f5f5f5;
}

.bg-black {
  background-color: #222222;
}

.bg-white {
  background-color: #fff;
}

img {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -user-select: none;
  -webkit-user-drag: none;
  -user-drag: none;
}

a,
a:hover,
a:active,
a:focus {
  text-decoration: none;
  outline: none;
}

a,
button,
a span {
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

a {
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.btn:focus,
.btn:active {
  outline: inherit;
  background-color: transparent;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

p {
  margin: 0 0 15px;
}

.clear {
  clear: both;
}

img {
  max-width: 100%;
}

textarea,
input[type='text'],
input[type='password'],
input[type='datetime'],
input[type='datetime-local'],
input[type='date'],
input[type='month'],
input[type='time'],
input[type='week'],
input[type='number'],
input[type='email'],
input[type='url'],
input[type='search'],
input[type='tel'],
input[type='color'],
.uneditable-input {
  outline: none;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background-color: transparent;
  background-image: none;
  color: rgb(0, 0, 0);
}

.form-control {
  border-radius: 0px;
  border: 1px solid #ccc;
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075);
  width: 100%;
  height: 50px;
}

.form-control:focus {
  border-color: #bcefeb;
  outline: 0;
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075), 0 0 0px rgba(102, 175, 233, 0.6);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: #1a1a1a;
  margin-bottom: 10px;
}

h1 {
  font-size: 3rem;
  line-height: 4rem;
}

h2 {
  font-size: 2.5rem;
  line-height: 3rem;
}

h3 {
  font-size: 1rem;
  font-size: 1.5rem;
  /* line-height: 1rem; */
}

h4 {
  font-size: 1.6rem;
  line-height: 2.2rem;
}

h5 {
  font-size: 1.125rem;
  line-height: 1.575rem;
}

h6 {
  font-size: 1rem;
}

h1 span,
h2 span,
h3 span,
h4 span,
h5 span,
p span {
  color: #3f92d1;
}

p {
  line-height: 1.5rem;
}

p:last-child {
  margin-bottom: 0px;
}

.w100 {
  font-weight: 100;
}

.w200 {
  font-weight: 200;
}

.w300 {
  font-weight: 300;
}

.w400 {
  font-weight: 400;
}

.w500 {
  font-weight: 500;
}

.w600 {
  font-weight: 600;
}

.w700 {
  font-weight: 700;
}

.w800 {
  font-weight: 800;
}

.parallax-section {
  max-width: 100%;
  color: #ffffff;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.overlay {
  /* background-color: rgba(41, 39, 34, 0.5); */
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.no-padding {
  padding: 0 !important;
  margin: 0 !important;
}

.left-no-padding {
  padding-left: 0 !important;
}

.right-no-padding {
  padding-right: 0 !important;
}

.fluid-blocks-col {
  padding: 70px 40px 0 80px;
}

.fluid-blocks-col-right {
  padding: 70px 80px 0 40px;
}

.separator_left {
  width: 85px;
  height: 2px;
  margin: 20px 0px;
  background: #3f92d1;
}

.separator_auto {
  width: 85px;
  height: 2px;
  margin: 20px auto;
  background: #3f92d1;
}

.separator_small {
  width: 30px;
  height: 2px;
  margin: 20px 0px;
  background: #3f92d1;
}

.btn {
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid;
  padding: 1rem 4rem;
}

.btn-primary {
  color: #fff;
  background-color: #3f92d1;
  border: 1px solid;
  border-color: #3f92d1;
}

.btn-primary:hover {
  background-color: #eee;
  border-color: #eee;
  color: #3f92d1;
}

.btn-default {
  color: #fff;
  background-color: transparent;
  border: 1px solid;
  border-color: #b6b6b6;
}

.btn-default:hover {
  color: #fff;
  background-color: #3f92d1;
  border-color: #3f92d1;
}

.m-0 {
  margin: 0 !important;
}

.m-top-0 {
  margin-top: 0;
}

.m-r-0 {
  margin-right: 0;
}

.m-l-0 {
  margin-left: 0;
}

.m-b-0 {
  margin-bottom: 0;
}

.m-r-15 {
  margin-right: 15px;
}

.m-l-15 {
  margin-left: 15px;
}

.m-top-10 {
  margin-top: 10px;
}

.m-top-20 {
  margin-top: 20px;
}

.m-top-30 {
  margin-top: 30px;
}

.m-top-40 {
  margin-top: 40px;
}

.m-top-50 {
  margin-top: 50px;
}

.m-top-60 {
  margin-top: 60px;
}

.m-top-70 {
  margin-top: 70px;
}

.m-top-80 {
  margin-top: 80px;
}

.m-top-90 {
  margin-top: 90px;
}

.m-top-100 {
  margin-top: 100px;
}

.m-top-110 {
  margin-top: 110px;
}

.m-top-120 {
  margin-top: 120px;
}

.m-t-b-80 {
  margin-top: 80px;
  margin-bottom: 80px;
}

.m-t-b-120 {
  margin-top: 120px;
  margin-bottom: 120px;
}

.m-bottom-10 {
  margin-top: 10px;
}

.m-bottom-20 {
  margin-bottom: 20px;
}

.m-bottom-30 {
  margin-bottom: 30px;
}

.m-bottom-40 {
  margin-bottom: 40px;
}

.m-bottom-60 {
  margin-bottom: 60px;
}

.m-bottom-70 {
  margin-bottom: 70px;
}

.m-bottom-80 {
  margin-bottom: 80px;
}

.m-bottom-90 {
  margin-bottom: 90px;
}

.m-bottom-100 {
  margin-bottom: 100px;
}

.m-bottom-110 {
  margin-bottom: 110px;
}

.m-bottom-120 {
  margin-bottom: 120px;
}

.m_t__b {
  margin: 14rem 0;
}

.p_t__b {
  padding: 6rem 0;
}

p-0 {
  padding: 0 !important;
}

.p-l-15 {
  padding-left: 15px;
}

.p-r-15 {
  padding-right: 15px;
}

.p-top-10 {
  padding-top: 10px;
}

.p-top-20 {
  padding-top: 1.33rem;
}

.p-bottom-20 {
  padding-bottom: 1.33rem;
}

.p-top-30 {
  padding-top: 1.875rem;
}

.p-bottom-30 {
  padding-bottom: 1.875rem;
}

.p-top-40 {
  padding-top: 2.66rem;
}

.p-bottom-40 {
  padding-bottom: 2.66rem;
}

.p-top-60 {
  padding-top: 5rem;
}

.p-bottom-60 {
  padding-bottom: 5rem;
}

.p-top-80 {
  padding-top: 5.714rem;
}

.p-bottom-80 {
  padding-bottom: 5.714rem;
}

.p-top-90 {
  padding-top: 6.429rem;
}

.p-bottom-90 {
  padding-bottom: 6.429rem;
}

.p-top-100 {
  padding-top: 100px;
}

.p-bottom-100 {
  padding-bottom: 100px;
}

.p-top-110 {
  padding-top: 110px;
}

.p-bottom-110 {
  padding-bottom: 110px;
}

.p-bottom-0 {
  padding-bottom: 0;
}

.p_l_r {
  padding-left: 5.714rem;
  padding-right: 5.714rem;
}

.padding-twenty {
  padding: 10px 0;
}

.padding-fourty {
  padding: 20px 0;
}

.padding-sixty {
  padding: 30px 0;
}

.padding-eighty {
  padding: 40px 0;
}

.no-border-top {
  border-top: 0px solid transparent !important;
}

.no-border-rigth {
  border-right: 0px solid transparent !important;
}

input[type='email'].form-control::-webkit-input-placeholder {
  color: #5a5a5a;
  background-color: transparent;
}

input::-webkit-input-placeholder {
  color: #cdcdcd;
}

input::-moz-placeholder {
  color: #cdcdcd;
}

input:-ms-input-placeholder {
  color: #cdcdcd;
}

input::placeholder {
  color: #cdcdcd;
}

input::-webkit-input-placeholder {
  color: #cdcdcd;
}

input::-moz-placeholder {
  color: #cdcdcd;
}

input::-ms-input-placeholder {
  color: #cdcdcd;
}

textarea::-webkit-input-placeholder {
  color: #cdcdcd;
  padding: 5px;
}

textarea::-moz-input-placeholder {
  color: #cdcdcd;
  padding: 5px;
}

textarea::-ms-input-placeholder {
  color: #cdcdcd;
  padding: 5px;
}

.transform_scal_rotate:hover img {
  -webkit-transform: scale(1.1) rotate(4deg);
  transform: scale(1.1) rotate(4deg);
}

.culmn {
  width: 100%;
  overflow: hidden;
}

.fix {
  overflow: hidden;
}

.head_title {
  padding-bottom: 2.143rem;
  overflow: hidden;
}

.head_title h2 {
  font-weight: 100;
  font-size: 2.25rem;
}

.head_title h5 {
  font-weight: 400;
  font-size: 1.125rem;
  color: #333;
}

#loading {
  background-color: #3f92d1;
  height: 100%;
  width: 100%;
  position: absolute;
  /* z-index: 1; */
  margin-top: 0px;
  top: 0px;
  left: 0;
  z-index: 9999;
}

#loading-center {
  width: 100%;
  height: 100%;
  position: relative;
}

#loading-center-absolute {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 50px;
  width: 50px;
  margin-top: -25px;
  margin-left: -25px;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-animation: loading-center-absolute 1.5s infinite;
  animation: loading-center-absolute 1.5s infinite;
}

.object {
  width: 25px;
  height: 25px;
  background-color: #fff;
  float: left;
}

#object_one {
  -webkit-animation: object_one 1.5s infinite;
  animation: object_one 1.5s infinite;
}

#object_two {
  -webkit-animation: object_two 1.5s infinite;
  animation: object_two 1.5s infinite;
}

#object_three {
  -webkit-animation: object_three 1.5s infinite;
  animation: object_three 1.5s infinite;
}

#object_four {
  -webkit-animation: object_four 1.5s infinite;
  animation: object_four 1.5s infinite;
}

@-webkit-keyframes loading-center-absolute {
  100% {
    -webkit-transform: rotate(-45deg);
  }
}

@keyframes loading-center-absolute {
  100% {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
}

@-webkit-keyframes object_one {
  25% {
    -webkit-transform: translate(0, -50px) rotate(-180deg);
  }

  100% {
    -webkit-transform: translate(0, 0) rotate(-180deg);
  }
}

@keyframes object_one {
  25% {
    transform: translate(0, -50px) rotate(-180deg);
    -webkit-transform: translate(0, -50px) rotate(-180deg);
  }

  100% {
    transform: translate(0, 0) rotate(-180deg);
    -webkit-transform: translate(0, 0) rotate(-180deg);
  }
}

@-webkit-keyframes object_two {
  25% {
    -webkit-transform: translate(50px, 0) rotate(-180deg);
  }

  100% {
    -webkit-transform: translate(0, 0) rotate(-180deg);
  }
}

@keyframes object_two {
  25% {
    transform: translate(50px, 0) rotate(-180deg);
    -webkit-transform: translate(50px, 0) rotate(-180deg);
  }

  100% {
    transform: translate(0, 0) rotate(-180deg);
    -webkit-transform: translate(0, 0) rotate(-180deg);
  }
}

@-webkit-keyframes object_three {
  25% {
    -webkit-transform: translate(-50px, 0) rotate(-180deg);
  }

  100% {
    -webkit-transform: translate(0, 0) rotate(-180deg);
  }
}

@keyframes object_three {
  25% {
    transform: translate(-50px, 0) rotate(-180deg);
    -webkit-transform: translate(-50px, 0) rotate(-180deg);
  }

  100% {
    transform: translate(0, 0) rotate(-180deg);
    -webkit-transform: rtranslate(0, 0) rotate(-180deg);
  }
}

@-webkit-keyframes object_four {
  25% {
    -webkit-transform: translate(0, 50px) rotate(-180deg);
  }

  100% {
    -webkit-transform: translate(0, 0) rotate(-180deg);
  }
}

@keyframes object_four {
  25% {
    transform: translate(0, 50px) rotate(-180deg);
    -webkit-transform: translate(0, 50px) rotate(-180deg);
  }

  100% {
    transform: translate(0, 0) rotate(-180deg);
    -webkit-transform: translate(0, 0) rotate(-180deg);
  }
}

.navbar {
  padding: 0;
}

.navbar-brand {
  padding: 15px 15px;
}

.navbar-brand>img {
  display: block;
  width: 80%;
}

nav.navbar.bootsnav ul.nav li.active>a {
  color: #3f92d1;
}

nav.navbar.bootsnav ul.nav li a:hover {
  color: #3f92d1;
}

.navbar-nav>li>a {
  padding-top: 25px;
  padding-bottom: 20px;
}

.home {
  /* background: url(../images/home-bg.jpg) no-repeat top center; */
  background-color: #11708b;
  background-size: cover;
  position: relative;
  padding-top: 130px;
  width: 100%;
}

.main_home .home_text h2 {
  font-size: 36px;
  font-weight: 400;
}

.main_home .home_text h1 {
  font-size: 48px;
  font-weight: 700;
}

.main_home .home_text h3 {
  font-size: 24px;
  font-weight: 400;
}

.main_home:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-bottom: 140px solid #f5f5f5;
  border-right: 690px solid transparent;
}

.main_home:after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-bottom: 140px solid #f5f5f5;
  border-left: 690px solid transparent;
}

.home_btns {
  margin-left: -10px;
}

.home_btns .btn {
  margin-left: 10px;
}

.features_item .f_item_icon {
  float: left;
  width: 15%;
  display: inline-block;
  margin-right: 15px;
}

.features_item .f_item_icon i {
  font-size: 1.5rem;
  color: #454545;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  border: 1px solid #454545;
  display: inline-block;
  border-radius: 50%;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.features_item .f_item_text {
  display: inline-block;
  width: 75%;
}

.features_item .f_item_text p {
  font-size: 17px;
}

.features_item:hover .f_item_icon i {
  background-color: #2c82c9;
  border-color: #2c82c9;
  color: #fff;
}

.features_item:hover .f_item_text h3 {
  color: #2c82c9;
}

.main_dialogue {}

.main_dialogue h3 {
  font-size: 36px;
  font-weight: 300;
  line-height: 3.5rem;
}

.business {
  background: url(../images/business-bg.jpg) no-repeat top center;
  background-size: cover;
  position: relative;
}

.business_overlay {
  background-color: #83acce80;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
}

.main_business {}

.business_item {}

.business_item h2 {
  font-size: 26px;
  font-weight: 800;
}

.business_item li {
  font-weight: normal;
  line-height: 2rem;
  font-size: 17px;
}

/* CHange  font-size:18px;  */
.business_item li i {
  font-size: 12px;
  margin-right: 5px;
}

.business_item p {
  color: #333333;
  line-height: 2rem;
  font-size: 14px;
  font-weight: 500;
}

.business_item .business_btn {
  margin-left: -10px;
}

.business_item .business_btn a {
  margin-left: 10px;
}

.business_item .business_btn .btn-default {
  color: #444444;
}

.main_product .carousel-control {
  background-image: none;
  color: #ddd;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 3rem;
  text-align: center;
  top: 30%;
}

.main_product .carousel-control.left {
  left: -4%;
}

.main_product .carousel-control.right {
  right: -4%;
}

.main_product .port_img {
  position: relative;
  overflow: hidden;
}

.main_product .port_img img {
  width: 100%;
}

.main_product .port_overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 168, 133, 0.6);
  position: absolute;
  left: 0;
  top: 0;
  padding-top: 50%;
  opacity: 0;
  transition: all 0.2s;
}

.main_product .port_overlay a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 0 auto;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 50%;
  display: inline-block;
  font-size: 2rem;
  bottom: -150px;
  position: relative;
  margin-top: -20px;
}

.main_product .port_img:hover .port_overlay a {
  bottom: 0;
}

.main_product .port_img:hover .port_overlay {
  opacity: 1;
}

.team_item {}

.main_team .team_item .team_author {}

.main_team .team_item .team_author h4 {
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 0px;
}

.main_team .team_item .team_author p {
  font-size: 14px;
  font-weight: 500;
}

.main_team .team_skill_title h5 {
  font-size: 18px;
  font-weight: 800;
}

.main_team .team_content h4 {
  font-size: 24px;
  font-weight: 300;
}

.main_team .team_content h5 {
  font-size: 18px;
  font-weight: 500;
}

.main_team .team_socail .team_socail h5 {
  font-size: 18px;
  font-weight: 800;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #ddd;
  display: inline-block;
  margin: 30px 0px;
}

.main_team .team_socail li a i {
  font-size: 20px;
  color: #ddd;
  padding: 5px;
  transition: all 0.6s;
}

.main_team .team_socail li a:hover i {
  color: #2c82c9;
}

.main_team .carousel-indicators {
  bottom: -40px;
  width: 64%;
}

.main_team .carousel-indicators li {
  background-color: #c1c1c1;
}

.main_team .carousel-indicators .active {
  background-color: #2c82c9;
}

.teamskillbar {
  position: relative;
  display: block;
  margin-bottom: 15px;
  width: 100%;
  background: #ddd;
  height: 3px;
  -webkit-transition: 0.4s linear;
  transition: 0.4s linear;
  -webkit-transition-property: width, background-color;
  transition-property: width, background-color;
}

.teamskillbar h6 {
  position: absolute;
  top: -25px;
  left: 0;
}

.teamskillbar-bar {
  height: 3px;
  width: 0px;
  background: #2c82c9;
  position: absolute;
  left: 0px;
  top: 0px;
}

.brand_item {
  height: 80px;
}

.test {
  background: url(../images/test-bg.jpg) no-repeat top center;
  background-size: cover;
  position: relative;
}

.main_test {
  width: 100%;
  display: block;
}

.main_test {}

.main_test .test_item {}

.main_test .test_item .test_text {
  width: 80%;
  margin: 30px auto;
}

.main_test .test_item .test_text em {
  font-size: 18px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
}

.main_test .carousel-control {
  background-image: none;
  top: 50%;
  font-size: 3rem;
}

.business_btn .btn,
.maine_action .btn {
  background-color: #fff;
  /* box-shadow: 0px 3px 0px 1px #ddd; */
  border: 0px;
  color: #555;
  font-size: 14px;
  padding: 1rem 2rem;
}

.business_btn .btn-default:hover,
.action_btn .btn-default:hover {
  background-color: #fff;
  color: #3f92d1;
}

.action-lage {
  position: relative;
  width: 100%;
}

.action-lage:before {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 20px solid #3f92d1;
  left: 50%;
  top: 0%;
  z-index: 3;
  margin-left: -15px;
}

.widget_ab_item_text h6 {
  margin-bottom: 5px;
}

.widget_item h5 {
  font-weight: 700;
}

.widget_item .btn:hover {
  background-color: #3f92d1;
}

.widget_item li a {
  color: #aaa;
}

.widget_item li a:hover {
  color: #fff;
}

.widget_latst_item_text p {
  margin-bottom: 0px;
  color: #aaaaaa;
}

.widget_service li i,
.widget_latst_item_text a {
  font-size: 12px;
  color: #aaaaaa;
}

.widget_service li i {
  margin-right: 5px;
}

.widget_service li {
  font-size: 13px;
  border-bottom: 1px dashed #aaa;
  padding-bottom: 10px;
}

.widget_newsletter li a:hover i,
.widget_latst_item_text:hover p,
.widget_service li:hover,
.widget_service li:hover i {
  color: #fff;
}

.widget_ab_item .item_icon,
.widget_latst_item .item_icon {
  float: left;
  width: 20%;
  display: inline-block;
  margin-right: 15px;
}

.widget_ab_item .widget_ab_item_text,
.widget_latst_item .widget_latst_item_text {
  display: inline-block;
  width: 70%;
}

.widget_ab_item .item_icon {
  float: left;
  width: 10%;
  display: inline-block;
  margin-right: 15px;
}

.widget_ab_item .widget_ab_item_text {
  display: inline-block;
  width: 80%;
}

.widget_newsletter .form-inline .form-group {
  display: block;
}

.widget_newsletter .form-control {
  width: 80%;
  height: 40px;
  background-color: #1a1a1a;
  border-color: #333333;
  display: inline-block;
}

.widget_newsletter .btn {
  width: 20%;
  padding: 0.65rem 1.2rem;
  background-color: #444444;
  border-color: #444444;
  color: #fff;
  border-radius: 0;
  margin-left: -4px;
  display: inline-block;
}

.widget_brand a {
  font-size: 30px;
  font-weight: 800;
  color: #aaa;
}

.widget_newsletter li a i {
  color: #aaa;
}

.main_footer i {
  padding: 0px 5px;
  color: #ff0000;
}

/* For Logo In Mobile */
.bulletpoint {
  margin-left: -15px;
  /*  font-size: 15px !important; */
}

@media screen and (min-width: 750px) {
  .logo {
    margin-top: -8px;
  }
}