#tokenPrice {
    padding: 1.4rem 2rem;
}

#tokenPrice h5 {
    color: #fff;
    text-align: left;
}

#tokenPrice h5:nth-child(2) {
    margin: 0;
}

.app-brand-text.demo {
    font-size: 1.5rem;
}

.layout-menu-toggle {
    cursor: pointer;
    /* display: none !important; */
    transition: all 300ms ease-in-out;
}

/* .layout-menu-toggle:hover {
    filter: brightness(0.9);
} */

@media (max-width: 1199px) {
    .layout-menu {
        transform: translate3d(-105%, 0, 0);
    }

    .layout-menu-toggle {
        display: inline-flex !important;
    }
}

.sidebar {
    background: #000;
    color: #fff;
    height: 100vh;
    width: 260px;
    transition: all 0.5s;
}

.sidenavClosed {
    transition: width 0.3s ease-in-out;
    width: 90px;
}

@media (max-width: 600px) {
    /* .sidenavClosed {
      background-color: #000;
      color: #fff;
      height: 100vh;
      width: -20px;
      transition: all 0.5s;
    } */

    .sidebar {
        transition: width 0.3s ease-in-out;
        width: 240px;
    }
}