.customerRow {
  // margin: 0;
  // padding: 0;
  margin-left: 0.5em !important;
  align-items: center;
}

.customerAddressRow {
  // margin: 0;
  // padding: 0;
  margin-bottom: 0.5em !important;
  margin-left: 0.5em !important;
  align-items: center;
}

.customerHeading {
  background: transparent;

  font-weight: 700;
}

.customer_gst .MuiPaper-root {
  // background: #EAECEE;
}

.customer_gst .MuiInputBase-root {
  background: #fff;
}

.customer_gst hr {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-color: #D5D8DC;
}