.forgotPasswordSubTitle {
    text-align: left;
}

#formAuthentication {
    margin-top: 2em;
}

#backToLoginBtn {
    color: gray;
    text-transform: capitalize;
    border: 1px solid lightgrey;
    font-weight: 500;
    color: gray;
    padding: 0.5rem;
}

#logoStyle {
    max-width: 7em;
    max-height: 7em;
}