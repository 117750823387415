.rcmCodeList {
  margin-top: 2em;
}

.rcmSupplierRow {
  margin-top: 2em;
}

.supplierRcm {
  margin-top: 0em;
}

@media (max-width: 800px) {
  .supplierRcm {
    margin-top: 7em;
  }
}