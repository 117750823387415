.divHeader {
  display: block;
  background-color: #fff;
}
.sacCard {
  scrollbar-width: thin;
  font-size: small;
}
.btnCardHeader {
  text-align: center;
}
.ulStyle {
  display: block;
  margin: 0 !important;
  padding: 0 !important;
}
.list-group-item {
  display: block;
  border: 1px solid #ddd;
}

.sacmain {
  font-size: small;
}
