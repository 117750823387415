.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* .MuiOutlinedInput-input {
  padding: 13.5px 9px 17.5px 9px !important;
} */

/* .regCountry .MuiOutlinedInput-input {
  padding: 2px !important;
}

.MuiOutlinedInput-input {
  padding: 12.5px 14px !important;
} */

/* .MuiInputBase-input {

  height: 0.2em !important
} */

.card {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
}

.cardHeaderStyle {
  border-bottom: 1px solid lightgrey !important;
  padding: 1em !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  top: 8px;
  left: 5px;
}

.MuiTableSortLabel-root {
  font-family: apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;

  color: #fff;
}

/* Material Table Properties  */
.MuiPaper-elevation2 {
  display: grid;
  padding: 0px !important;
}

.MuiTableRow-root {
  border: 1px solid #e2dddd;
}

.MuiTableCell-body {
  border: 1px solid #e2dddd;
}

.MuiTableCell-head {
  border: 1px solid #fff;
}

/* .MuiInputBase-input {} */

.MuiTableCell-root {
  padding-top: 0.3em !important;
  padding-bottom: 0.3em !important;
  word-break: break-all;
}

/* .MuiTableCell-root MuiTableCell-body {} */

.MuiTypography-h6 {
  color: #6b7580 !important;
  font-size: 16px !important;
  font-weight: 700 !important;
}

.MuiIconButton-root {
  padding: 0px !important;
}

.MuiToolbar-regular * {
  min-height: max-content;
  margin: 0;
}

.MuiToolbar-regular {
  min-height: max-content;
  padding: 0.5em 1em !important;
  min-height: 48px !important;
  margin: 0;
}

.MuiTableRow-root:hover {
  background: #dce0dc;
}

.MuiTableRow-root {
  max-height: fit-content !important;
  height: max-content !important;
}

.ToggleButtonActive {
  text-transform: none;
  background-color: #27ae60 !important;
  color: #fff !important;
  width: 4.5rem !important;
  height: 2rem !important;
  margin: 0.5rem 0rem 0.5rem 0rem !important;
  border-radius: 3px !important;
}

.ToggleButtonInActive {
  text-transform: none;
  background-color: #c7c7c7 !important;
  color: #000 !important;
  width: 4.5rem !important;
  height: 2rem !important;
  margin: 0.5rem 0rem 0.5rem 0rem !important;
  border-radius: 3px !important;
}

.css-l3ln04-MuiAutocomplete-root .MuiInput-root .MuiInput-input {
  padding: 6px !important;
}

.cardHeaderStyle {
  border-bottom: 1px solid lightgrey !important;
}

.css-l3ln04-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0px !important;
}

.css-xg9u7b-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0px !important;
}

.css-4xbbh5-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0px !important;
}

.file {
  height: 0em;
}

.fileterButton {
  padding: 5px;
  width: 100px;
  font-size: 15px;
  border-radius: 5px;
  border: none;
  color: #fff;
  background-color: #1b7189;
}

.resetButton {
  padding: 5px;
  width: 100px;
  font-size: 15px;
  border-radius: 5px;
  border: none;
  color: #fff;
  background-color: black;
}

.gstnBtn {
  cursor: pointer;
  height: 40.8px !important;
  background: green !important;
  color: #fff !important;
  width: 40px !important;
  padding: 4px !important;
  margin-left: 0em !important;
  margin-right: -0.7em !important;
  /* margin-top: -0.25em; */
}

.orggstnBtn {
  height: 40.8px !important;
  background: green !important;
  color: #fff !important;
  width: 40px !important;
  padding: 4px !important;
  margin-left: 0em !important;
  margin-right: -0.7em !important;
  cursor: pointer;
  /* margin-top: -0.25em; */
}

.tableThead th {
  background-color: rgb(27, 113, 137);
  color: #fff !important;
  font-weight: 700 !important;
}

.gsearchButton {
  /* background-color: rgb(242, 109, 33); */
  background-color: rgb(27, 113, 137);
  color: #fff;
  border: "none";
  height: 32px;
  width: 50px;
  border-radius: 0 5px 5px 0;
  font-size: 15px;
}

#gsearch {
  width: 220px;
  height: 32px;
  outline: none;
}

.totalResults {
  font-size: 18px;
  font-weight: 600;
  color: black;
}

#headerLabel {
  font-weight: bold !important;
  color: #252733 !important;
  font-size: 18px;
}

@media (max-width: 800px) {
  .minMaxNavbar {
    width: 60%;
    margin: 0px;
    float: right;
    position: fixed;
    margin-left: 20px !important;
  }
}

@media (max-width: 992px) {
  .side-menu-collapsed .app-sidebar {
    width: 65px !important;
  }
}

.MuiOutlinedInput-multiline {
  padding: 0px !important;
}

.table.table {
  color: #000;
}

.disableFiled .MuiOutlinedInput-input {
  background-color: rgb(247 247 247);
}

.selectDropdown .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.23);
  top: -5px !important;
}

/* .MuiIconButton-label {
  margin-left: 10px;
} */

.salesDialogue .MuiDialog-paperScrollPaper {
  width: 75% !important;
}

.text-underline-hover {
  text-decoration: none;
}

.text-underline-hover :hover {
  text-decoration: underline;
}

.MuiTableCell-paddingCheckbox:last-child {
  width: 80px !important;
}

canvas {
  width: 100% !important;
  align-items: center !important;
}

#app {
  background-color: #fff !important;
}

.deleteDialog .MuiDialog-paperScrollPaper {
  width: 600px !important;
}

.myCustomList::-webkit-scrollbar {
  width: 10px;
  height: 20px;
  background-color: rgb(232, 233, 239);
}

.myCustomList::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: gray;
}

.MuiCheckbox-root {
  width: 30px !important;
}

.MuiFormHelperText-contained {
  margin-left: 0px !important;
  font-size: 12px !important;
}

.errorMassageShow {
  color: red;
  margin: 0 0 0 5px;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}

table.table thead th {
  padding: 10px 20px !important;
}

table.table tbody td {
  padding: 6px 20px !important;
}
