.bankAccountRow {
  margin: 0;
  padding: 0;
  align-items: center;
}

.bankAccountHeading {
  background: transparent;
  font-size: xx-large;
  font-weight: 700;
}

.activeToggeleButton {
  background: 'green';
  color: '#fff';
}

.inactiveToggeleButton {
  background: 'white';
  color: '#000';
}
