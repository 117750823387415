.addressRow {
  margin: 0;
  padding: 0;
  align-items: center;
}

.addressHeading {
  background: transparent;
  font-size: xx-large;
  font-weight: 700;
}

.activeToggeleButton {
  background: 'green';
  color: '#fff';
}

.inactiveToggeleButton {
  background: 'white';
  color: '#000';
}
.faDiv {
  float: right;
  margin-top: -0.5em;
}
.UploadLog .MuiInputBase-input {
  height: 1.2em !important;
}