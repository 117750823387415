// .expenseAutoComplete {
//   font-size: small !important;
//   margin-left: 0.5em;
//   background: antiquewhite;
//   box-shadow: 0 0 3px #b9a78f;
// }

// .expenseAutoComplete MuiAutocomplete-clearIndicato {
//   display: none;
// }

.react-calendar {
  box-shadow: 1px 1px 1px 1px #10313a;
  border: none;
}
.react-date-picker__wrapper {
  // border: 0.5px solid #10313a !important;
  border: none !important;
  // border-radius: 10%;
  // color: #fff !important;
  background: #d2e2f7;
}

.react-calendar__navigation {
  display: flex;
  background: #e0e8ea;
}

.expenseCard {
  width: 100%;
  color: #000;
  font-size: small;
  padding: 0;
  margin: 0;
}

.expenseHeaderRow {
  flex-wrap: wrap;
  align-items: stretched;
  display: flex;
  // padding: 5px;
  // margin: 5px;
  // padding: 5px;
  // box-shadow: 1px 1px 2px lightgrey;
  border: 2px solid white;
  // background: #f5faf9;
}

.expenseHeaderColumnColored {
  padding: 0;
  margin: 0;
  text-align: left;
  flex-wrap: wrap;
  // background: #f4fdffd9;
  display: flex;
  align-items: flex-end;
  border: 2px solid #fff;
  border-radius: 4%;
  background: #f5faf9;
}

.expenseHeaderSupplier {
  padding: 0;
  margin: 0;
  text-align: left;
  flex-wrap: wrap;
  background: #f9f7f5;
  display: flex;
  align-items: center;
  border: 2px solid #fff;
  border-radius: 4%;
}

.expenseHeaderColumnNonColored {
  // flex-direction: column;
  padding: 0;
  margin: 0;
  display: flex;
  text-align: left;
  align-items: flex-end;
  flex-wrap: wrap;
  border: 2px solid #fff;
  border-radius: 4%;
  background: #f5faf9;
}

.a4size {
  background: white;
  min-width: 25cm;
  min-height: 35.35cm;
  display: block;
  margin: 0 auto;
  // margin-bottom: 0.5cm;
  // box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
}

@media print {
}
