/* SLIDE PANEL STYLES
   ----------------------------- */

.slide-panel {
  position: fixed;
  right: -$slidePanelWidth;
  top: 0;
  width: $slidePanelWidth;
  background: #fff;
  bottom: 0;
  // z-index: 1000;
  border-left: 1px solid rgba(222, 227, 231, 0.56);
  transition: transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-perspective: 1000;

  &.open {
    transform: translate3d(-100%, 0, 0);
  }
}

.slide-panel-header {
  padding: 20px;
  border-bottom: 1px solid #eee;
}
.close-slide-panel {
  position: absolute;
  right: 0;
  top: 0;
  padding: 20px;
  color: #aaa;

  &:hover {
    color: darken(#aaa, 50%);
  }
}

// responsive

@media screen and (max-width: $breakPointSm) {
  .slide-panel {
    width: 100%;
    right: -100%;
  }
}
