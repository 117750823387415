// .bankAccountRow {
//   margin: 0;
//   padding: 0;
//   align-items: center;
// }

// .bankAccountHeading {
//   background: transparent;
//   font-size: xx-large;
//   font-weight: 700;
// }

// .activeToggeleButton {
//   background: 'green';
//   color: '#fff';
// }

// .inactiveToggeleButton {
//   background: 'white';
//   color: '#000';
// }
// .documentFile {
//   height: 4em !important;
// }

.documentFile .MuiInputBase-input {
  height: 1.2em !important;
}

.pg-viewer-wrapper {
  overflow-y: unset !important;
}

.photo-viewer-container {
  width: unset !important;
  height: unset !important;
}

.photo-viewer-container>img {
  width: unset !important;
  height: unset !important;
}