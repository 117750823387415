/* BUTTON STYLES
   ----------------------------- */
.btn {
	border-radius: $btnBorderRadius;
	transition: all $btnTransitionSpeed ease;

	&.btn-primary {
		background-color: $btnColorPrimary;
		@include buttonBorder($btnColorPrimary);

		&:focus,
	    &:hover {
	    	background-color: darken($btnColorPrimary, 10%);
	    	@include buttonBorder(darken($btnColorPrimary, 10%));
	    }
	}

	&.btn-secondary {
		@include defaultButtonStyle;
	}

	&.btn-success {
		color: #fff;
		background-color: $btnColorSuccess;
		@include buttonBorder($btnColorSuccess);

		&:focus,
	    &:hover {
	    	background-color: darken($btnColorSuccess, 10%);
	    	@include buttonBorder(darken($btnColorSuccess, 10%));
	    }
	}

	&.btn-danger {
		color: #fff;
		background-color: $btnColorDanger;
		@include buttonBorder($btnColorDanger);

		&:focus,
	    &:hover {
	    	background-color: darken($btnColorDanger, 10%);
	    	@include buttonBorder(darken($btnColorDanger, 10%));
	    }
	}

	&.btn-info {
		color: #fff;
		background-color: $btnColorInfo;
		@include buttonBorder($btnColorInfo);

		&:focus,
	    &:hover {
	    	background-color: darken($btnColorInfo, 10%);
	    	@include buttonBorder(darken($btnColorInfo, 10%));
	    }
	}

	&.btn-warning {
		color: #fff;
		background-color: $btnColorWarning;
		@include buttonBorder($btnColorWarning);

		&:focus,
	    &:hover {
	    	background-color: darken($btnColorWarning, 10%);
	    	@include buttonBorder(darken($btnColorWarning, 10%));
		}

		&:active {
			color: #fff!important;
		}
	}

	&.btn-outline-primary {
		@include buttonBorder($btnColorPrimary);

		&:focus,
	    &:hover {
	    	@include buttonBorder($btnColorPrimary);
	    }
	}
	&.btn-outline-secondary {
		@include buttonBorder($btnColorSecondary);

		&:focus,
	    &:hover {
	    	@include defaultButtonStyle;
	    	@include buttonBorder(darken($btnColorSecondary, 10%));
	    }
	}
	&.btn-outline-success {
		@include buttonBorder($btnColorSuccess);

		&:focus,
	    &:hover {
	    	@include buttonBorder($btnColorSuccess);
	    }
	}
	&.btn-outline-info {
		@include buttonBorder($btnColorInfo);

		&:focus,
	    &:hover {
		@include buttonBorder($btnColorInfo);
		}
	}
	&.btn-outline-warning {
		@include buttonBorder($btnColorWarning);

		&:focus,
	    &:hover {
		@include buttonBorder($btnColorWarning);
		}
	}
	&.btn-outline-danger {
		@include buttonBorder($btnColorDanger);

		&:focus,
	    &:hover {
		@include buttonBorder($btnColorDanger);
		}
	}
}


.addCircleIconBtn{
	background: $addCircleIconBtnBgColor;
    color: $addCircleIconBtnFontColor;
    font-size:$addCircleIconBtnFontSize !important;
}
.BackButtonBtn{
	background: $BackButtonBtnBgColor !important;
    color: $BackButtonBtnFontColor !important;
}
.backIconButton{
	background: $backIconButtonBgColor !important;
    color: $backIconButtonFontColor !important;
    font-size:$backIconButtonFontSize !important;
}
.cancelIconButton{
	background: $cancelIconButtonBgColor !important;
    color: $cancelIconButtonFontColor !important;
}
.checkIconButton{
	background: $checkIconButtonBgColor;
    color: $checkIconButtonFontColor;
    font-size:$checkIconButtonFontSize !important;
}
.createButton{
	background: $createButtonBgColor !important;
    color: $createButtonFontColor !important;
}

.createSimilarIconButton{
	background: $createSimilarIconButtonBgColor;
    color: $createSimilarIconButtonFontColor;
    font-size:$createSimilarIconButtonFontSize !important;
}

.deleteIconButton{
	background: $deleteIconButtonBgColor;
    color: $deleteIconButtonFontColor;
    font-size:$deleteIconButtonFontSize !important;
}

.downloadIconButton{
	background: $downloadIconButtonBgColor;
    color: $downloadIconButtonFontColor;
    font-size:$downloadIconButtonFontSize !important;
}

.editIconButton{
	background: $editIconButtonBgColor;
    color: $editIconButtonFontColor;
    font-size:$editIconButtonFontSize !important;
}

.generateIconButton{
	background: $generateIconButtonBgColor;
    color: $generateIconButtonFontColor;

		/* padding: 1.3em 0em 0.2em 0em; */
		margin-top: 0.8em;
		height: 42px;
		border-radius: 3px;
		padding-top: 0.4em;
}

.genericButton{
	background: $genericButtonBgColor;
    color: $genericButtonFontColor;
}