.businessUnitRow {
  margin: 0;
  padding: 0;
  align-items: center;
}

.pts_inpuTextFiled {
}
.businessUnitHeading {
  background: transparent;
  font-size: xx-large;
  font-weight: 700;
}

.activeToggeleButton {
  background: 'green';
  color: '#fff';
}

.inactiveToggeleButton {
  background: 'white';
  color: '#000';
}

.planDateTimeInput {
  width: 100%;
  height: 42px;
  padding: 10px;
}
