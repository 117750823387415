.fa-user-circle,
.fa-sign-out,
.fa-user-circle,
.fa-cog {
  font-size: 1.3em;
  color: #566573;
}
.user {
  color: #566573;
  font-weight: 700;
  line-height: 2.3em;
}
.applicationFooter {
  color: #fff;
  background-color: #000;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  overflow: hidden;


}