
#supplierReport .MuiPaper-elevation2 {
    padding: 0em !important;
    box-shadow: none !important;
}

#supplierReport .MuiTableHead-root {
    // display: table-header-group;
    background: rgb(197, 197, 197) !important;
}
#supplierReport .MuiTableCell-root {
    display: table-cell;
    padding: 9px !important;
}

.bonus-icon {
    visibility: hidden;
}

.casino-btn:hover .bonus-icon {
    visibility: visible;
}