.productCard {
  height: 300px;
}

.productEditCard {
  height: 300px;
}

#submitButton {
  background-color: #1a7088;
  color: #fff;
}

#cancleButton {
  background-color: #777777;
  color: #fff;
}

#cancleNavlink {
  background-color: #777777;
  color: #fff;
}

.product_gst .MuiPaper-root {
  background: #fff;
  padding: 1em;
}

.product_gst .MuiInputBase-root {
  background: #fff;
}

.product_gst hr {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-color: #D5D8DC;
}