// .roleRow {
//   margin: 0;
//   padding: 0;
//   align-items: center;
// }

// .roleHeading {
//   background: transparent;
//   font-size: xx-large;
//   font-weight: 700;
// }

// .activeToggeleButton {
//   background: 'green';
//   color: '#fff';
// }

// .inactiveToggeleButton {
//   background: 'white';
//   color: '#000';
// }

// .ptsIcon a {
//   cursor: 'pointer';
//   // float: right;
//   // text-align: right;
//   display: 'inline-block';
//   // float: left;
//   width: 28px;
//   height: 28px;
//   //border: 2px solid #909090;
//   //border-radius: 20px;
//   margin-right: 7px; /*space between*/
// }
// .ptsIcon a i {
//   display: 'inline-block';
//   cursor: 'pointer';
//   // float: right;
//   padding: 5px;
//   font-size: large;
//   color: black;
// }

.pull-right {
  float: right !important;
  position: absolute;
}

.fa-minus {
  color: #1B7189;
  text-decoration: none;
  cursor: pointer;
  font-size: 18px;
}

.fa-plus {
  color: #1B7189;
  text-decoration: none;
  cursor: pointer;
  font-size: 18px;
}

.list-group-item {
  display: block;
  padding: 10px 15px;
  margin-bottom: 1px;
  color: black;
  background-color: #fff;
  border: 1px solid #ddd;
}

.list-group-item:hover {
  color: black;
  background-color: #fff;
}

element.style {
  width: 25%;
  height: 256px;
}

.list-group-item:first-child {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.list-group>li {
  list-style: none;
  padding: 0.8em;
}

.updateButton {
  width: 110px;
  padding: 5px;
  border-radius: 5px;
  border: none;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background-color: rgb(11, 111, 157);
}

.cancelButton {
  width: 110px;
  padding: 5px;
  border-radius: 5px;
  border: none;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background-color: rgb(113, 115, 115);
}

.checkbox {
  width: 15px;
  height: 15px;
}

.MuiTabs-scroller MuiTabs-fixed {
  overflow: auto;
}

// .settingTabRow{
//   margin: 0em 0.5em;
// }
.tabSetting {
  padding: 8px;
  /* height: 40px; */
  width: 160px;
  margin-right: 10px;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  cursor: pointer;
  color: black;
  background-color: #cad2d3;
}

.settingTab .react-tabs__tab-list {
  border-bottom: none !important;
  margin: 0 0 0px;
  padding: 0;
}

.settingTab .react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
  background: #fff;
  margin: 0em 0.5em 0em 0em;
  color: black !important;
  border-radius: 5px 5px 0px 0px;
}

.settingTab .react-tabs__tab--selected {
  background-color: #005476 !important;
  border-color: #aaa;
  color: #fff !important;
  border-radius: 5px 5px 0 0;
  border: none;
  pointer-events: none;
}

.settingTab .react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  margin: 0 0 0px;
  padding: 0;
  // display: grid !important;
}

.hoverTab:hover {
  background-color: #005476;
  color: #fff;
}

.expenseCard {
  padding: 0em !important;
}

.list-group {
  margin-bottom: 5px !important;
}