/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

body {
  margin: 0;
  padding-right: 0px !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#application-layout #layout-menu {
  position: fixed;
}

/* #application-layout .layout-page {
  margin-left: 16rem;
} */

/* Regular loading classes */
.page-loader {
  height: 3rem;
  width: 3rem;
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: -2;
  transform: translate(-50%, -100%);
}

.show-loader {
  z-index: 10001;
  display: block;
}

/* Loader with text classes */
.text-loader {
  display: none;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1.1rem;
  font-size: 1.2rem;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: -2;
  cursor: none;
  pointer-events: none;
  transform: translate(-50%, -100%) !important;
}

.text-loader-show {
  display: flex;
  z-index: 10001;
}

/* Overlay classes */
/* .overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: whitesmoke;
  opacity: 0;
  z-index: -1;
  transition: all 300ms ease-in-out;
} */

.overlay-active {
  opacity: 0.6;
  z-index: 10000;
}

.custom-placeholder {
  display: flex;
}

fieldset legend {
  width: unset;
}

#password-requirements-tooltip .MuiTooltip-tooltip {
  max-width: 20rem !important;
  background-color: #3e3a3a;
  border-radius: 0.5rem;
  padding: 0.7rem;
}

#password-requirements-tooltip .MuiTooltip-tooltip .MuiTooltip-arrow {
  color: #3e3a3a;
}

.asterisk {
  color: red;
  font-size: 1rem;
}

.text-align-center {
  text-align: center;
}

.react-tabs__tab:focus:after {
  content: none !important;
}

.btn {
  transition: all 100ms ease-in-out;
}

.layout-menu {
  transition: all 300ms ease-in-out !important;
}

.sidebar-menu-extended #layout-menu {
  transform: translate3d(0, 0, 0) !important;
  z-index: 11000 !important;
}

.ml-04 {
  margin-left: 0.4rem;
}

.mr-04 {
  margin-right: 0.4rem;
}

.input-group:focus-within {
  box-shadow: none;
}

.PrivateNotchedOutline-root-1 {
  top: 0px !important;
}

.green-check-modal {
  width: 32rem !important;
  display: grid;
  gap: 1rem;
  justify-content: center;
  text-align: center;
}

.green-check-modal img {
  margin: auto;
}

.green-check-modal h3,
.green-check-modal p {
  margin: 0;
}

.justify-content-space-between {
  justify-content: space-between;
}

/* ======== MUI Overrides ======== */
.Mui-selected {
  background: rgba(105, 108, 255, 0.08) !important;
  /* color: #696cff !important; */
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #696cff !important;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #696cff !important;
}

.Mui-checked.MuiSwitch-colorPrimary,
.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track,
.Mui-checked {
  color: #696cff !important;
}

.MuiAutocomplete-loading {
  min-height: 10rem;
}

.MuiAutocomplete-loading .show-loader {
  transform: translate(-50%, -50%);
}

.MuiDataGrid-menu {
  z-index: 1300;
  inset: 0px 0px auto auto !important;
}

.MuiDataGrid-panel {
  z-index: 1300;
  /* inset: -80px auto auto 640px !important; */
  top: -80px !important;
  right: auto !important;
  bottom: auto !important;
  left: 640px !important;
}

.MuiTablePagination-root .MuiTablePagination-selectLabel {
  margin-bottom: 0 !important;
}

@media (max-width: 2580px) {
  .MuiDataGrid-panel {
    left: 33vw !important;
  }
}

@media (max-width: 1200px) {
  #application-layout .layout-page {
    margin-left: 0;
  }

  .MuiDataGrid-panel {
    left: 23vw !important;
  }
}

/* SCROLLBAR STYLING */
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: #888; */
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.userNameInitial {
  background-color: #696cff !important;
  width: 40px !important;
  height: 40px !important;
  color: #fff !important;
  font-weight: 500 !important;
  font-size: 22px !important;
  margin-top: 0px !important;
  text-transform: uppercase !important;
  border: 3px solid #bdbdbd !important;
  border-radius: 20px !important;
}

.userNameInitial:hover {
  background-color: #595cd9 !important;
}

.MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator {
  opacity: 0 !important;
}

.ptsmodal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 9999 !important;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: transparent;
  /* Black w/ opacity */
}

.ptsmodalHide {
  display: none;
  opacity: 0;
}

.ptsloader {
  position: fixed;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 4.5em;
  height: 4.5em;
  border: 1.1em solid rgba(53, 18, 209, 0.2);
  border-left: 1em solid #f26d21;
  border-radius: 50%;
  background: transparent;
  /* animation: load8 1.1s infinite linear; */
  transition: opacity 0.3s;
  /* position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 6em;
  height: 6em;
  border: 1em solid back;
  border-left: 1em solid #f26d21;
  border-radius: 50%; */
  /* animation: load8 1.1s infinite linear; */
  /* transition: opacity 0.3s; */
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  /* -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); */
  opacity: 7000;
  z-index: 9999 !important;
  overflow: auto;
}

.ptsloaderHide {
  opacity: 0;
}

#ptsButton {
  background: #0b6f9d;
  color: #fff;
  border-color: #0b6f9d;
  font-weight: bold;
}

.searchButton {
  background-color: #0b6f9d;
  color: #fff;
  border-color: #0b6f9d;
  height: 34px;
  width: 60px;
  border-radius: 0 5px 5px 0;
  font-size: 15px;
  border: none;
}

.card {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
}

.gstMenu {
  background: #3a90a8 !important;
}

.gstMenuDark {
  background: #2e7387 !important;
}

.custBtnIcon {
  background-image: url("./assets/images/cust1.png");
  background-repeat: no-repeat;
  display: inline-block;
  height: 100%;
  width: 100%;
  background-size: contain;
  background-position: center;
}

.suppBtnIcon {
  background-image: url("./assets/images/supp1.png");
  background-repeat: no-repeat;
  display: inline-block;
  height: 100%;
  width: 100%;
  background-size: contain;
  background-position: center;
}

.serviceBtnIcon {
  background-image: url("./assets/images/service.png");
  background-repeat: no-repeat;
  display: flex;
  height: 100%;
  width: 100%;
  background-size: contain;
  background-position: center;
}

.goodsBtnIcon {
  background-image: url("./assets/images/goods.png");
  background-repeat: no-repeat;
  display: flex;
  height: 100%;
  width: 100%;
  background-size: contain;
  background-position: center;
}

.salesVBtnIcon {
  background-image: url("./assets/images/sa.png");
  background-repeat: no-repeat;
  display: flex;
  height: 100%;
  width: 100%;
  background-size: contain;

  background-position: center;
}

.receiptVBtnIcon {
  background-image: url("./assets/images/re.png");
  background-repeat: no-repeat;
  display: flex;
  height: 100%;
  width: 100%;
  background-size: contain;

  background-position: center;
}

.cnBtnIcon {
  background-image: url("./assets/images/cn.png");
  background-repeat: no-repeat;
  display: flex;
  height: 100%;
  width: 100%;
  background-size: contain;

  background-position: center;
}

.dnBtnIcon {
  background-image: url("./assets/images/dn.png");
  background-repeat: no-repeat;
  display: flex;
  height: 100%;
  width: 100%;
  background-size: contain;

  background-position: center;
}

.rfBtnIcon {
  background-image: url("./assets/images/rf.png");
  background-repeat: no-repeat;
  display: flex;
  height: 100%;
  width: 100%;
  background-size: contain;

  background-position: center;
}

.psalesVBtnIcon {
  background-image: url("./assets/images/sa.png");
  background-repeat: no-repeat;
  display: flex;
  height: 100%;
  width: 100%;
  background-size: contain;

  background-position: center;
}

.preceiptVBtnIcon {
  background-image: url("./assets/images/re.png");
  background-repeat: no-repeat;
  display: flex;
  height: 100%;
  width: 100%;
  background-size: contain;

  background-position: center;
}

.pcnBtnIcon {
  background-image: url("./assets/images/cn.png");
  background-repeat: no-repeat;
  display: flex;
  height: 100%;
  width: 100%;
  background-size: contain;

  background-position: center;
}

.pdnBtnIcon {
  background-image: url("./assets/images/dn.png");
  background-repeat: no-repeat;
  display: flex;
  height: 100%;
  width: 100%;
  background-size: contain;

  background-position: center;
}

.prfBtnIcon {
  background-image: url("./assets/images/rf.png");
  background-repeat: no-repeat;
  display: flex;
  height: 100%;
  width: 100%;
  background-size: contain;

  background-position: center;
}

.rcmBtnIcon {
  background-image: url("./assets/images/rcm.png");
  background-repeat: no-repeat;
  display: flex;
  height: 100%;
  width: 100%;
  background-size: contain;

  background-position: center;
}

.pimpBtnIcon {
  background-image: url("./assets/images/purchase_import.png");
  background-repeat: no-repeat;
  display: flex;
  height: 100%;
  width: 100%;
  background-size: contain;

  background-position: center;
}

.pvBtnIcon {
  background-image: url("./assets/images/pv.png");
  background-repeat: no-repeat;
  display: flex;
  height: 100%;
  width: 100%;
  background-size: contain;

  background-position: center;
}

.gstrIcon {
  background-image: url("./assets/images/gstr1.png");
  background-repeat: no-repeat;
  display: flex;
  height: 100%;
  width: 100%;
  background-size: contain;

  background-position: center;
}
