#ledgerTable .MuiTableCell-root {
  padding-top: 0.3em !important;
  padding-bottom: 0.3em !important;
  word-break: normal !important;
}

#ledgerTable table {
  max-width: 1600;
}

.ledgerTable ::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  background-color: rgb(232, 233, 239);
}

.ledgerExpenseTable ::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  background-color: rgb(232, 233, 239);
}

.ledgerTable ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #a7a4a4;
}
.ledgerExpenseTable ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #a7a4a4;
}

.ledgerTable .MuiTable-root {
  width: 160% !important;
}

.ledgerExpenseTable .MuiTable-root {
  width: 110% !important;
}

.tooltip {
  background-color: #ffffff;
  color: #000000;
  border: 0.5px solid #999999;
  font-size: 0.85rem;
  font-weight: 400;
}

#ledgerTooltip .MuiTooltip {
  .tooltip {
    font-size: 12px;
    color: black;
    background-color: #f3e3a3;
    border: 1px solid black;
    cursor: pointer;
  }
}
