.ml-auto {
  margin-bottom: -1em;
}

.minNavbar {
  margin: 0px;
  // float: right;
  width: 100%;
  position: fixed;
  margin-left: 7em;
}

.maxNavbar {
  margin: 0px;
  // float: right;
  width: 100%;
  position: fixed;
  margin-left: 19em;
}

.blink_me {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  80% {
    opacity: 0.5;
  }
}