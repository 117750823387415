.policyList li {
  list-style: disc !important;
  margin-left: 2em;
}

.privacyPolicy ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

.privacyPolicy ol > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.8em;
}

.privacyPolicy ol > li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.6em;
  font-weight: bold;
}

.privacyPolicy li ol > li {
  margin: 0;
}

.privacyPolicy li ol > li:before {
  content: counters(item, ".") " ";
  font-weight: bold;
}
.privacyPolicy p {
  text-align: justify;
  margin-bottom: 0.5em;
}

.privacyPolicy ul {
  list-style: disc;
  margin-left: 20px;
}
