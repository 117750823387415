.dashboardRow {
  align-items: center;
  align-content: center;
  // padding-bottom: 2em;
  width: '100%';
  max-width: '100%';
  display: 'flex';
  flex-direction: row;
  font-size: 'small';
}

.dashboardButton {
  display: block;
  background: #1a7088 !important;
  color: #fff !important;
  float: 'left' !important;
  margin-top: 0.5em;
}
.subMenuLabel {
  color: #fff;
  word-wrap: break-word;
  float: left !important;
  display: inline-block;
}

.subMenuValue {
  color: #fff;
  float: right !important;
  word-wrap: break-word;
  display: inline-block;
}
.subMenuTable {
  display: inline-block;
  float: right;
  text-align: right;
  width: 100% !important;
  padding: 0;
  margin: 0;
}
.menucard {
  margin: 0.5em !important;
  padding: 0 !important;
  overflow: auto;
  align-self: flex-end;
  display: inline-block;
  max-height: 2em !important;
}

.subMenuCol {
  color: #fff;
  margin: 0;
  padding: 0;
}

.customerCount{
background: $customerCountBgColor;
}
.salesCount{
  background: $salesCountBgColor;
}
.supplierCount{
  background: $supplierCountBgColor;
}
.purchaseCount{
  background: $purchaseCountBgColor;
}
.purchaseNogGstCount{
  background: $purchaseNogGstCountBgColor;
}

.customerCountDark{
  background: $customerCountDarkBgColor;
}
.salesCountDark{
  background: $salesCountDarkBgColor;
}
.supplierCountDark{
  background: $supplierCountDarkBgColor;
}
.purchaseCountDark{
  background: $purchaseCountDarkBgColor;
}
.purchaseNogGstCountDark{
  background: $purchaseNogGstCountDarkBgColor;

}

