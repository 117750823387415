.divHeader {
  margin: -1.2em -1em 1em -1.2em;
  background-color: #fff;
  padding: 1em 0em 1em 0em;
}
.subMenu {
  background-color: #3a90a8;
  color: #fff;
  height: 5em;
  margin-bottom: 1em;
  border-radius: 10px;
  cursor: pointer;
}
.subMenuColumn {
  background-color: #2e7387;
  height: 5em;
  padding-left: 3em;
  padding-top: 1em;
  cursor: pointer;
}
.salesVBtnIcon {
  background-image: url('../../../assets/images/sa.png');
  background-repeat: no-repeat;
  height: 82px;
  background-size: 3.5em;
  background-position: center;
}
