.rcmCodeList {
  margin-top: 2em;
}

.rcmSupplierRow {
  margin-top: 2em;
}

.supplier_gst .MuiPaper-root {
  background: #fff;
  padding: 1em;
}

.supplier_gst .MuiInputBase-root {
  background: #fff;
}

.supplier_gst hr {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-color: #D5D8DC;
}