
#supplierReport .MuiPaper-elevation2 {
    padding: 0em !important;
    box-shadow: none !important;
}

#supplierReport.MuiPaper-rounded {
    border-radius: 4px;
    padding: 0em !important;
}

#supplierReport .MuiTableHead-root {
    // display: table-header-group;
    background: rgb(197, 197, 197) !important;
}