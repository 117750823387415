.bankAccountRow {
  margin: 0;
  padding: 0;
  align-items: center;
}

.bankAccountHeading {
  background: transparent;
  font-size: xx-large;
  font-weight: 700;
}

.activeToggeleButton {
  background: 'green';
  color: '#fff';
}

.inactiveToggeleButton {
  background: 'white';
  color: '#000';
}

.tableHeader {
  padding: 0;
  margin: 0;
  min-height: 2em;
  /* max-height: 0; */
  border: 1px solid black;
  // font-size: small;
  text-align: center;
}