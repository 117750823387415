.userDetail {
  background-color: #efefef !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
  height: 23em;
}

.rowClass {
  margin-top: 1em;
}

.textHeader {
  //   text-align: center !important;
  //   font-size: 2em !important;
  text-align: center;
  font-weight: bold;
  color: #fff;
}

.MuiInputLabel-outlined {
  z-index: 1;
  transform: translate(14px, 20px) scale(1);
  pointer-events: none;
  margin: -0.5em;
  height: 2em;
}

.cardHeaderText {
  font-size: 1.5em;
  padding: 1em 0em 0em 0em;
}

.regCountry .MuiOutlinedInput-input {
  padding: 2px !important;
}

.signupButton {
  background-color: #0B6F9D;
  width: 400px;
  height: 40px;
  color: #FFFFFF;
  font-size: 18px;
  font-family: 'Poppins';
  font-style: normal;
  align-items: center;
  border: none;
  border-radius: 5px;
}

.backSignUp {
  font-size: 18px;
  padding: 5px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 5px;
  border: none;
  color: #fff;
  background-color: lightslategray;
}

.submitSignup {
  font-size: 18px;
  padding: 5px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 5px;
  border: none;
  color: #fff;
  background-color: lightslategray;
}

.nextSignUp {
  font-size: 18px;
  padding: 5px;
  align-items: end;
  padding-left: 30px;
  padding-right: 30px;
  /* margin-left: 430px; */
  border-radius: 5px;
  border: none;
  color: #fff;
  background-color: #0B6F9D;
}

.rsglogoImage {
  width: 75%;
  height: 220px;
  // margin-top: 45px;
  margin-left: 1px;
}

@media (max-width: 950px) {
  .rsglogoImage {
    width: 50%;
    height: 150px;
    margin-top: 45px;
    margin-left: 1px;
  }
}

// .MuiInputBase-input {

//   height: 0.2em !important
// }