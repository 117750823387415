/* GLOBAL STYLES
   ----------------------------- */

body {
  background-color: $globalBackgroundColor;
  // font-family: $globalFontFamily;
  font-family: apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif !important;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $globalFontSize;
}

hr {
  border-color: $hrColor;
}
